











































import Vue from "vue"
import {Component, Watch, Prop} from "vue-property-decorator"
import {namespace} from "vuex-class"

import PensiongoalInput from "custom/PensiongoalInput.vue"
import RangeInput from "custom/RangeInput.vue"

import ActionButton from "components/button/ActionButton.vue"
import {Pensiongoal} from "store/pensiongoal/types"

import {cloneDeep} from "lodash-es"

import smoothScroll from "components/@directives/smoothscroll"

const pensiongoal = namespace("pensiongoal")

@Component({
	components: {
		ActionButton,
		PensiongoalInput,
		RangeInput
	},
	directives: {
		smoothScroll
	}
})
export default class PensiongoalForm extends Vue {
	@Prop({default: "", type: String, required: false}) smoothScrollId!: string
	@pensiongoal.State items!: Pensiongoal["items"]
	@pensiongoal.Getter isEdited!: boolean
	max = 9999
	pensiongoalItems: Pensiongoal["items"] = []

	@Watch("items", {deep: true})
	pgChanged() {
		this.pensiongoalItems = cloneDeep(this.items)
	}

	mounted() {
		this.pgChanged()
	}

	saveResult() {
		if (this.isValid) {
			this.$store.commit("pensiongoal/updateItems", this.pensiongoalItems)
			this.$emit("saved")
		}
	}

	deselectAverage() {
		this.pensiongoalItems = this.pensiongoalItems.map(i => ({...i, current: undefined}))
	}

	selectAverage() {
		this.pensiongoalItems = this.pensiongoalItems.map(i => ({...i, current: i.average}))
	}

	scrollOptions(step: string) {
		return {
			offset: -105,
			duration: 1000,
			target: step,
			delay: 100
		}
	}

	get isValid() {
		return this.pensiongoalItems.every(item => !item.current || (Number(item.current) >= 0 && Number(item.current) <= this.max))
	}

	get valuesChanged(): boolean {
		return this.pensiongoalItems.some(i => i.current !== undefined)
	}

	get netMonthly(): number {
		return this.pensiongoalItems.reduce((acc, i) => i.current ? i.current + acc : acc, 0)
	}
}
