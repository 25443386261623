import {Pensiongoal, PensiongoalItem} from "./types"
import {ConversionMap} from "lib/types/import"
import {one, many, mandatory} from "lib/import/extract"
import {numeric, alphanumeric} from "lib/import/convert"

const required = true
const optional = false

const mapDefault = (value?: number) => typeof value === "number" ? value : 0

const pgItem: ConversionMap<PensiongoalItem> = {
	title: ["title", alphanumeric, required],
	current: ["nonexistent", () => undefined, optional],
	average: ["average", numeric, required]
}

const pensiongoal: ConversionMap<Pensiongoal> = {
	items: ["attributes.pensiongoal.items", many(pgItem), required],
	value: ["attributes.pensiongoal.default", mapDefault, required],
	isEdited: ["nonexistent", () => false, required]
}

export const convert = mandatory(one(pensiongoal))
