// Serialize dates to ISO string. Most browsers do this, but this is not specified, so enforce it.
const replacer = (_key: string, value: any): any => value instanceof Date ? value.toISOString() : value

// Convert ISO strings to Date instances.
const reviver = (_key: string, value: any): any =>
	/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/.test(value)
		? new Date(value)
		: value

/**
 * The `StorageController` is a proxy that stores values in a `Storage` using JSON. It takes care to serialize
 * and deserialize `Date`s to and from JSON.
 *
 * Additionally, it reads all values from the `Storage` into a private store, so that values exist in the `Storage`
 * for a short duration only.
 */
export default class StorageController {
	private readonly values = new Map<string, any>()

	constructor(private readonly storage: Storage) {
		// Move everything over to our private store.
		for (let i = 0; i < storage.length; i += 1) {
			const key = storage.key(i)!
			this.values.set(key, JSON.parse(storage.getItem(key)!, reviver))
		}
		storage.clear()

		if (!process.env.SERVER) {
			addEventListener("beforeunload", () => {
				this.flush()
			})
		}
	}

	retrieve(key: string): any {
		return this.values.get(key)
	}

	store(key: string, value: any): void {
		this.values.set(key, value)
	}

	discard(key: string): void {
		this.values.delete(key)
	}

	flush(): void {
		for (const [key, item] of this.values) {
			this.storage.setItem(key, JSON.stringify(item, replacer))
		}
	}

}
