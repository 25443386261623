import {Datatype, FieldErrors, FieldRules, FieldValidation, FieldValidations, Rules, Scalar} from "lib/types/validation"
import {isEmpty, isNil, toString, compact, isUndefined} from "lodash-es"
import {Function2} from "lib/types/function"
import {datetime, alphanumeric, numeric, bool, file} from "lib/import/convert"
import {ConversionFunction} from "lib/types/import"
import entries from "lib/misc/entries"

const isFieldRule = <T, F extends keyof T>(fieldValue: any): fieldValue is Rules<F, T> =>
	!isUndefined(fieldValue.required) && !isUndefined(fieldValue.type)
const isFieldRules = <T, F extends keyof T>(fieldValue: any): fieldValue is FieldRules<T[F]> =>
	!isFieldRule<T, F>(fieldValue)

const typeConverters: {[T in Datatype<Scalar>]: ConversionFunction<any>} = {
	boolean: bool,
	date: datetime,
	number: numeric,
	string: alphanumeric,
	file
}

const converter = (parameter: keyof typeof typeConverters) => typeConverters[parameter]
const values = (parameter: ReadonlyArray<any>) => (value: any) => parameter.includes(value) // TODO: does not work for dates.
// String:
const maxlength = (parameter: number) => (value: string) => value.length <= parameter
const minlength = (parameter: number) => (value: string) => value.length >= parameter
const pattern = (parameter: RegExp) => (value: string) => parameter.test(value)
// Number or Date:
const maximum = <T extends Date | number>(parameter: T) => (value: T) => value <= parameter
const minimum = <T extends Date | number>(parameter: T) => (value: T) => value >= parameter

export const ruleTypes: ReadonlyArray<keyof Rules<any>> = ["minlength", "maxlength", "minimum", "maximum", "pattern", "values", "valid"]

/**
 * Returns a map of fields to a validation function.
 *
 * @argument {FieldRules<T>} fieldRules
 * @returns {FieldValidations<T>}
 */
const validations = <T>(fieldRules: FieldRules<T>): FieldValidations<T> => {
	const fieldValidations: Partial<FieldValidations<T>> = {}

	for (const [field, fieldValue] of entries(fieldRules)) {
		if (isFieldRule<T, typeof field>(fieldValue)) {

			const convert = converter(fieldValue.type)
			// Create a map of rule checks to call in order. First create an array of [rule, predicate] entries, then use that
			// to create the Map.
			const mapEntries = ruleTypes
				.filter(rule => !isNil(fieldValue[rule]))
				.map(
					rule => {
						const verifier = rule === "pattern" ? pattern(fieldValue.pattern!) :
							rule === "maxlength" ? maxlength(fieldValue.maxlength!) :
							rule === "minlength" ? minlength(fieldValue.minlength!) :
							rule === "maximum" ? maximum(fieldValue.maximum! as Date | number) :
							rule === "minimum" ? minimum(fieldValue.minimum! as Date | number) :
							rule === "values" ? values(fieldValue.values!) :
							rule === "valid" ? fieldValue.valid :
							undefined

						if (!verifier) {
							throw new TypeError(`Unsupported rule ${rule}`)
						}

						// All verifiers are predicates (value => boolean), except valid, which accepts a 2nd context argument.
						// Due to the dynamic nature we can cast all verifiers to Function2, but this should be improved.
						return [rule, verifier] as [keyof Rules<any>, Function2<any, Partial<T>, boolean>]
					}
				)

			const checks = new Map(mapEntries)

			const fieldValidation: FieldValidation<keyof T, T> = <K extends keyof T>(value: K, context: Partial<T>): FieldErrors<K> => {
				const required = typeof fieldValue.required === "function" ? fieldValue.required(context) : fieldValue.required || false
				if (isNil(value) || isEmpty(toString(value))) {
					// The check is successful if the field is not required.
					return required ? ["required"] : []
				}
				// Convert the value. This will return undefined if the value is not of the proper type.
				const convertedValue = convert(value)
				if (isUndefined(convertedValue)) {
					return ["type"]
				}

				// Run the checks.
				return compact([...checks.entries()].map(
					([rule, check]) => check(convertedValue, context) ? "" : rule
				))
			}
			fieldValidations[field] = fieldValidation as FieldValidations<T>[typeof field]
		} else if (isFieldRules<T, typeof field>(fieldValue)) {
			fieldValidations[field] = validations(fieldValue) as FieldValidations<T>[typeof field]
		}

	}

	return fieldValidations as FieldValidations<T>
}

export default validations
