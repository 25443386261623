












































































import Vue from "vue"
import Component from "vue-class-component"
import {namespace, State} from "vuex-class"

import {
	IconArrow,
	IconDocument,
	IconEnvelope,
	IconFolder,
	IconMapsFilled,
	IconPerson,
	IconPencil,
	IconPensiondetails,
	IconRepeat,
	IconStar
} from "custom/icons"

import IconLabel from "custom/IconLabel.vue"

import ActionButton from "components/button/ActionButton.vue"
import Badge from "components/supplemental/Badge.vue"
import Card from "components/panel/Card.vue"
import LinkButton from "components/button/LinkButton.vue"
import Modal from "components/modal/Modal.vue"

import {MeubelParticipant} from "store/participant/types"
import {StoreAction} from "lib/types/vuex"
import {CustomerJourneys} from "store/customerJourneys/types"

import eventBus from "lib/vue/eventBus"

const communication = namespace("communication")
const planner = namespace("planner")

@Component({
	components: {
		ActionButton,
		Badge,
		Card,
		IconLabel,
		LinkButton,
		Modal,

		// icons
		IconArrow,
		IconDocument,
		IconEnvelope,
		IconFolder,
		IconMapsFilled,
		IconPerson,
		IconPencil,
		IconPensiondetails,
		IconRepeat,
		IconStar
	}
})
export default class MenuModal extends Vue {
	@State participant!: MeubelParticipant
	@State customerJourneys!: CustomerJourneys

	@communication.Getter unreadMessages!: number
	@communication.Getter unreadDocuments!: number
	@planner.Action ontrack!: StoreAction<undefined>

	open = false

	get riskprofileStatus(): string {
		if (this.customerJourneys.riskprofile) {
			return this.customerJourneys.riskprofile.status
		}
		return "pending"
	}

	opened() {
		this.open = true
		this.$emit("opened")
	}

	closed() {
		this.open = false
		this.$emit("closed")
	}

	mounted() {
		eventBus.on(this, "menuModal", () => {
			if (this.open) {
				(this.$refs.modal as Modal).close()
				this.open = false
			} else {
				(this.$refs.modal as Modal).display()
				this.open = true
			}
		})
	}

	constructUrl(url: string) {
		return !process.env.SERVER
		? `${url}?person=${this.participant.firstName}`.toLowerCase()
		: url
	}
}
