









import Vue from "vue"
import Component from "vue-class-component"
import Modal from "components/modal/Modal.vue"
import {State} from "vuex-class"

import PensiongoalForm from "custom/PensiongoalForm.vue"
import {MeubelParticipant} from "store/participant/types"

@Component({
	components: {
		Modal,
		PensiongoalForm
	}
})
export default class PensiongoalModal extends Vue {
	@State participant!: MeubelParticipant

	close() {
		(this.$refs.modal as Modal).close()
		this.$emit("saved")
	}

	constructUrl(url: string) {
		return !process.env.SERVER
		? `${url}?person=${this.participant.firstName}`.toLowerCase()
		: url
	}
}
