import {Module, MutationTree} from "vuex"
import {RootState} from "../types"
import {Accrual} from "./types"

const accrualState: Accrual = {
	client: [],
	other: []
}

const mutations: MutationTree<Accrual> = {
	hydrate(state, data: Accrual) {
		Object.assign(state, data)
	}
}

const accrual: Module<Accrual, RootState> = {
	getters: {
		employment: state => state.client.find(i => i.active),
		monthlySalary: (_state, get, root) => get.employment && get.employment.salary / 12 * root.grossFactor || 0
	},
	mutations,
	namespaced: true,
	state: accrualState
}

export default accrual
