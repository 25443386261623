import {get} from "./request"
import {Store} from "vuex"
import {RootState} from "./types"
import {convert as convertParticipant} from "./participant/conversion"
import {convert as convertCommunication} from "./communication/conversion"
import {convert as convertAccrual} from "./accrual/conversion"
import {convert as convertCustomerJourneys} from "./customerJourneys/conversion"
import {convert as convertPensiongoal} from "./pensiongoal/conversion"
import {convert as convertPensiondetails} from "./pensiondetails/conversion"
import {convert as convertInvestments} from "./investments/conversion"
import {convert as convertRiskprofile} from "./riskprofile/conversion"
import {convert as convertVariablepension} from "./variablepension/conversion"
import {participantURL, sessionTimeout} from "./settings"
import load from "src/i18n/load"
import {save as saveLocale} from "lib/i18n/session"
import eventBus from "lib/vue/eventBus"

export default async (store: Store<RootState>) => {
	// await get(participantURL + "ping/") // Check live session.

	if (!store.state.timestamp || Date.now() - store.state.timestamp > sessionTimeout) {
		const response = await get(participantURL)()
		const body = await response.json()
		const participant = convertParticipant(body.data)
		const communication = convertCommunication(body.data)
		const accrual = convertAccrual(body.data)
		const customerJourneys = convertCustomerJourneys(body.data)
		const pensiongoal = convertPensiongoal(body.data)
		const investments = convertInvestments(body.data)
		const pensiondetails = convertPensiondetails(body.data)
		const riskprofile = convertRiskprofile(body.data)
		const variablepension = convertVariablepension(body.data)
		store.commit("participant/hydrate", participant)
		store.commit("communication/hydrate", communication)
		store.commit("customerJourneys/hydrate", customerJourneys)
		store.commit("accrual/hydrate", accrual)
		store.commit("pensiongoal/hydrate", pensiongoal)
		store.commit("pensiondetails/hydrate", pensiondetails)
		store.commit("investments/hydrate", investments)
		store.commit("riskprofile/hydrate", riskprofile)
		store.commit("variablepension/hydrate", variablepension)
		store.commit("timestamp")
		if (!store.state.locale) {
			store.commit("locale", "nl_NL")
			await saveLocale(load, "nl_NL")
		}
	}

	eventBus.emit("loaded")
}
