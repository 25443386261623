















































































































import Vue from "vue"
import Component from "vue-class-component"
import {namespace, State, Getter} from "vuex-class"

import ActionButton from "components/button/ActionButton.vue"
import PersonalData from "components/data/PersonalData.vue"
import Checkbox from "components/form/Checkbox.vue"
import InlineEditable from "components/form/InlineEditable.vue"
import LinkButton from "components/button/LinkButton.vue"
import ValidationMessage from "components/form/ValidationMessage.vue"
import PageSection from "components/layout/PageSection.vue"
import Modal from "components/modal/Modal.vue"

import {MeubelParticipant, Partner, Address} from "store/participant/types"
import {PensionPlan} from "store/accrual/types"

import {Gender, MaritalStatus, Status} from "lib/types/personal"

const participant = namespace("participant")
const communication = namespace("communication")

@Component({
	components: {
		ActionButton,
		Checkbox,
		InlineEditable,
		LinkButton,
		PageSection,
		PersonalData,
		Modal,
		ValidationMessage
	},
	filters: {
		formatGender: (gender: Gender) => {
			switch (gender) {
				case Gender.Male: return "Man"
				case Gender.Female: return "Vrouw"
				case Gender.Unknown: return "Onbekend"
			}
			return ""
		}
	}
})
export default class ProfileModal extends Vue {
	@State participant!: MeubelParticipant
	@Getter("accrual/employment") employment?: PensionPlan

	@participant.Getter phone?: string
	@participant.Getter partner?: Partner
	@participant.Getter address?: Address

	@communication.State pensionCommunication!: boolean

	get isMarried() {
		return this.partner && this.partner.maritalDate && (
			this.partner.maritalStatus === MaritalStatus.Married || this.partner.maritalStatus === MaritalStatus.LegalCohabitation
		)
	}

	formatMaritalStatus(maritalStatus: MaritalStatus) {
		switch (maritalStatus) {
			case MaritalStatus.Divorced: return "Gescheiden"
			case MaritalStatus.LegalCohabitation: return "Geregistreerd partnerschap"
			case MaritalStatus.LivingTogether: return "Samenwonend"
			case MaritalStatus.Married: return "Getrouwd/ geregistreerd partnerschap"
			case MaritalStatus.Single: return "Alleenstaand"
			case MaritalStatus.Unknown: return "Onbekend"
		}
		return ""
	}

	formatStatus(status: Status) {
		switch (status) {
			case Status.Active: return "Actief"
			case Status.Inactive: return "Inactief"
			case Status.Pensioner: return "Pensioengerechtigd"
			case Status.Unknown: return "Onbekend"
		}
		return ""
	}

}
