

















import Vue from "vue"
import Component from "vue-class-component"
import {State} from "vuex-class"

import PageHeader from "custom/PageHeader.vue"
import PageFooter from "custom/PageFooter.vue"

@Component({
	components: {
		PageHeader,
		PageFooter
	}
})
export default class Page extends Vue {
	@State expired!: boolean
}
