import {ActionTree, GetterTree, MutationTree} from "vuex"
import {Participant} from "./types"
import participantRules from "./validation"
import diff, {Diff} from "lib/date/diff"
import {Submit} from "lib/types/request"
import validationActions from "lib/vuex/validationActions"
import validationErrors from "timeline/request/validationErrors"

export const mutations: MutationTree<Participant> = {
	hydrate(state, data: Participant) {
		Object.assign(state, data)
	},
	email(state, value: string) {
		state.email = value
	},
	iban(state, value: string) {
		state.iban = value
	}
}

export const getters: GetterTree<Participant, any> = {
	age: state => diff(Diff.Months, state.birthDate, new Date()) / 12,
	address: state => state.addresses[0],
	phone: state => state.phoneNumbers[0] && state.phoneNumbers[0].number,
	partner: state => state.partners[0]
}

export const actions = <T extends Participant, R>(submit: Submit): ActionTree<T, R> =>
	validationActions(participantRules, submit, validationErrors)
