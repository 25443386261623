import {RootState} from "../types"
import {Pensiondetails, IncomeItem} from "./types"
import {Module} from "vuex"
import {sumBy} from "lodash-es"

const pensiondetails: Pensiondetails = {
	aow: {
		title: "AOW",
		id: 12345,
		single: {annual: 0, description: ""},
		married: {annual: 0, description: ""},
		removable: false,
		editable: true,
		maritalStatus: "single"
	},
	incomeItems: [],
	allianzPensionPolicies: [],
	isEdited: false
}

const pensiondetailsModule: Module<Pensiondetails, RootState> = {
	actions: {},
	getters: {
		maritalStatus: state => state.aow.maritalStatus,
		aowItem: state => {
			return ({
				id: state.aow.id,
				title: "AOW",
				description: state.aow[state.aow.maritalStatus].description,
				annual: state.aow[state.aow.maritalStatus].annual,
				fromAge: 67,
				untilAge: 0,
				lifelong: true,
				removable: state.aow.removable,
				editable: state.aow.editable
			})
		},
		incomeItems: (state, get) => [...state.incomeItems, get.aowItem as IncomeItem],
		pensionAmount: state => state.aow[state.aow.maritalStatus].annual,
		totalAllianzPension: state => state.allianzPensionPolicies.reduce((acc, curr) => acc + curr.annual, 0),
		totalOtherIncomes: (_state, get) => sumBy<IncomeItem>(get.incomeItems, item => item.fromAge < 69 ? item.annual : 0),
		totalAnnualPension: (_state, get) => get.totalAllianzPension + get.totalOtherIncomes,
		totalMonthlyNet: (_state, get, root) => (get.totalAnnualPension  / 12) * root.grossFactor,
		isEdited: state => state.isEdited
	},
	mutations: {
		hydrate(state, data: Pensiondetails) {
			if (data && !state.isEdited) {
				Object.assign(state, data)
			}
		},
		addIncome(state, income: IncomeItem) {
			state.incomeItems.push(income)
			state.isEdited = true
		},
		replaceIncome(state, income: IncomeItem) {
			const index = state.incomeItems.findIndex(i => income.id === i.id)
			if (index !== -1) {
				state.incomeItems.splice(index, 1, income)
			} else {
				state.incomeItems.push(income)
			}
			state.isEdited = true
		},
		removeIncome(state, income: IncomeItem) {
			const index = state.incomeItems.findIndex(i => income.id === i.id)
			if (index !== -1) { state.incomeItems.splice(index, 1) }
			state.isEdited = true
		},
		setAowAmounts(state, values: {single: number, married: number}) {
			state.aow.single.annual = values.single
			state.aow.married.annual = values.married
		},
		setMaritalStatus(state, maritalStatus: "married" | "single") {
			state.aow.maritalStatus = maritalStatus
			state.isEdited = true
		}
	},
	namespaced: true,
	state: pensiondetails
}

export default pensiondetailsModule
