export enum MaritalStatus {
	LegalCohabitation = "LegalCohabitation",
	LivingTogether = "LivingTogether",
	Married = "Married",
	Divorced = "Divorced",
	Single = "Single",
	Unknown = "Unknown"
}

export enum Gender {
	Female = "Female",
	Male = "Male",
	Third = "Third",
	Unknown = "Unknown"
}

export enum PhoneType {
	Mobile = "Mobile",
	Landline = "Landline",
	Other = "Other",
	Unknown = "Unknown"
}

export enum Status {
	Disabled = "Disabled",
	Active = "Active",
	Inactive = "Inactive",
	Pensioner = "Pensioner",
	Unknown = "Unknown"
}
