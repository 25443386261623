




















import Vue from "vue"
import Component from "vue-class-component"
import Modal from "components/modal/Modal.vue"
import {State} from "vuex-class"

import Tracker from "custom/Tracker.vue"
import TrackerItem, {TrackerStatus} from "custom/TrackerItem.vue"

import {MeubelParticipant} from "store/participant/types"

export interface TrackerItemData {
	status: TrackerStatus
	title: string
	date?: Date
}

@Component({
	components: {
		Modal,
		Tracker,
		TrackerItem
	}
})
export default class TraceModal extends Vue {
	@State participant!: MeubelParticipant

	trackerItems: Array<TrackerItemData> = [
		{
			status: TrackerStatus.Complete,
			title: "U bent gescheiden",
			date: new Date()
		},
		{
			status: TrackerStatus.Complete,
			title: "U heeft de verdeling doorgegeven",
			date: new Date()
		},
		{
			status: TrackerStatus.Processing,
			title: "Uw aanvraag wordt gecontroleerd"
		},
		{
			status: TrackerStatus.Pending,
			title: "Uw aanvraag is afgerond"
		}
	]

	trackerItems2: Array<TrackerItemData> = [
		{
			status: TrackerStatus.Complete,
			title: "U bent gescheiden",
			date: new Date()
		},
		{
			status: TrackerStatus.Complete,
			title: "U heeft de verdeling doorgegeven",
			date: new Date()
		},
		{
			status: TrackerStatus.Complete,
			title: "Uw aanvraag wordt gecontroleerd",
			date: new Date()
		},
		{
			status: TrackerStatus.Complete,
			title: "Uw aanvraag is afgerond",
			date: new Date()
		}
	]

}
