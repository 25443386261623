import {Investments, Graph, TransactionGroup, Transaction, Fund, GraphData} from "./types"
import {ConversionMap} from "lib/types/import"
import {one, many, mandatory} from "lib/import/extract"
import {numeric, alphanumeric, datetime} from "lib/import/convert"

const required = true

const fund: ConversionMap<Fund> = {
	name: ["name", alphanumeric, required],
	amount: ["amount", numeric, required],
	value: ["value", numeric, required]
}

const transaction: ConversionMap<Transaction> = {
	name: ["name", alphanumeric, required],
	funds: ["funds", many(fund), required]
}

const transactionGroup: ConversionMap<TransactionGroup> = {
	date: ["date", datetime, required],
	transactions: ["transactions", many(transaction), required]
}

const graphData: ConversionMap<GraphData> = {
	label: ["label", alphanumeric, required],
	data: ["data", many(numeric), required],
	backgroundColor: ["backgroundColor", alphanumeric, required]
}

const graph: ConversionMap<Graph> = {
	title: ["title", alphanumeric, required],
	labels: ["labels", many(alphanumeric), required],
	datasets: ["datasets", many(graphData), required]
}

const investments: ConversionMap<Investments> = {
	transactionGroups: ["attributes.investments.transactionGroups", many(transactionGroup), required],
	graph: ["attributes.investments.graph", one(graph), required]
}

export const convert = mandatory(one(investments))
