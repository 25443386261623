import {Pensiondetails, IncomeItem, Policy, Saving, AOW} from "./types"
import {ConversionMap} from "lib/types/import"
import {one, many, mandatory} from "lib/import/extract"
import {numeric, alphanumeric, bool} from "lib/import/convert"

const maritalStatusSpecifications = (value: any): {annual: number, description: string} | undefined => {
	const annual = value.annual
	const description = value.description
	return annual && description ? {annual, description} : undefined
}
const maritalStatus = (value: any): "married" | "single" | undefined => ["married", "single"].includes(value) ? value : undefined
const period = (value: any): "year" | "quarter" | "month" | undefined => ["year", "quarter", "month"].includes(value) ? value : undefined

const required = true
const optional = false

const aow: ConversionMap<AOW> = {
	title: ["nonexistent", () => "AOW", required],
	id: ["id", numeric, required],
	single: ["single", maritalStatusSpecifications, required],
	married: ["married", maritalStatusSpecifications, required],
	removable: ["removable", bool, required],
	editable: ["editable", bool, required],
	maritalStatus: ["maritalStatus", maritalStatus, required]
}

const saving: ConversionMap<Saving> = {
	currentWorth: ["currentWorth", numeric, required],
	increment: ["increment", numeric, required],
	period: ["period", period, required]
}

const policy: ConversionMap<Policy> = {
	id: ["id", alphanumeric, required],
	scheme: ["scheme", alphanumeric, required],
	annual: ["annual", numeric, required],
	grossAnnualSalary: ["grossAnnualSalary", numeric, required],
	pensionableAnnualSalary: ["pensionableAnnualSalary", numeric, required],
	pensionablePay: ["pensionablePay", numeric, required],
	franchise: ["franchise", numeric, required],
	currentWorth: ["currentWorth", numeric, required],
	expectedCapital: ["expectedCapital", numeric, required],
	riskProfile: ["riskProfile", alphanumeric, required],
	investmentProfile: ["investmentProfile", alphanumeric, required],
	currentPremium: ["currentPremium", numeric, required],
	scalePercentage: ["scalePercentage", numeric, required]
}

const incomeItem: ConversionMap<IncomeItem> = {
	id: ["id", numeric, required],
	title: ["title", alphanumeric, required],
	description: ["description", alphanumeric, required],
	fromAge: ["fromAge", numeric, required],
	untilAge: ["untilAge", numeric, optional],
	lifelong: ["lifelong", bool, optional],
	saving: ["saving", one(saving), optional],
	annual: ["annual", numeric, required],
	removable: ["removable", bool, optional],
	editable: ["editable", bool, optional]
}

const pensiondetails: ConversionMap<Pensiondetails> = {
	aow: ["attributes.pensiondetails.aow", one(aow), required],
	incomeItems: ["attributes.pensiondetails.incomeItems", many(incomeItem), required],
	allianzPensionPolicies: ["attributes.pensiondetails.allianzPensionPolicies", many(policy), required],
	isEdited: ["isEdited", () => false, required]
}

export const convert = mandatory(one(pensiondetails))
