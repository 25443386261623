












import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"

import {Message} from "store/communication/types"

import {IconEnvelope, IconEnvelopeOpened} from "custom/icons"

@Component({
	components: {
		IconEnvelope,
		IconEnvelopeOpened
	}
})
export default class MessageList extends Vue {
	@Prop({type: Array, required: true}) messages!: Array<Message>
	@Prop({type: Object, required: false}) selected!: Message

	private selectedMessage: Message | null = null

	@Watch("selected")
	selectedChanged() {
		this.selectMessage(this.selected)
	}

	selectMessage(message: Message) {
		this.selectedMessage = message
		this.$emit("selected", this.selectedMessage)
	}
}
