
















import Component from "vue-class-component"

import ActionButton from "components/button/ActionButton.vue"
import TextField from "components/form/TextField.vue"

import InputField from "components/form/InputField"

@Component({
	components: {
		ActionButton,
		TextField
	}
})
export default class InlineEditable extends InputField<string> {
	editing = false
	saving = false

	created() {
		this.internalValue = this.contents
		// The errors event is always emitted after saving. If successful, the errors array is empty.
		this.$on("errors", (errors: Array<string>) => {
			this.editing = errors.length > 0
			this.saving = false
		})
	}

	edit() {
		this.editing = true
	}

	save() {
		if (this.editing && !this.saving) {
			// Setting contents will save the value to its source (either the store, or the parent component using v-model).
			this.contents = this.internalValue
			this.saving = true
		}
	}

	cancel() {
		this.editing = false
	}
}
