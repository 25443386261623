











import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"

import Container from "components/generic/Container.vue"
import InputField from "components/form/InputField"
import DropdownItem from "custom/DropdownItem.vue"

import {single} from "components/@directives/selectable"

export interface ValueLabel<T> {
	value: T
	label: string
}

@Component({
	components: {
		Container,
		DropdownItem
	},
	directives: {
		single
	}
})
export default class Dropdown<T> extends InputField<T> {
	@Prop({type: Array, required: true, validator: (options: Array<any>) => options.length > 0}) options!: ReadonlyArray<ValueLabel<T>>

	active: boolean = false

	beforeMount() {
		if (this.selectedOption === null) {
			this.selectFirstOption()
		}
	}

	@Watch("options")
	selectFirstOption() {
		this.contents = this.options[0].value
	}

	get selectedOption(): ValueLabel<T> | null {
		return this.options.find(option => option.value === this.contents) || null
	}

	get label(): string {
		return this.selectedOption ? this.selectedOption.label : ""
	}

	clicked() {
		this.active = !this.active
	}

	select(selectedItem: Vue | undefined) {
		if (selectedItem) {
			const value: T = selectedItem.$props.value

			if (value) {
					this.contents = value
					this.clicked()
			}
		}
	}
}
