import request from "./request"
import {Submit, ErrorHandler} from "lib/types/request"

/**
 * Returns a function that accepts an object which is then sent to the server using POST.
 *
 * @returns {Submit} The function that performs the POST.
 */
export default (input: RequestInfo, failure: ErrorHandler<any>): Submit => {
	const submit = request(input, "POST")
	return (data?: any) => submit(data).catch(failure)
}
