import {cloneDeep} from "lodash-es"
import diff, {Diff} from "lib/date/diff"
import eventBus from "lib/vue/eventBus"
import {storageOptions} from "lib/storage/session"
import persist from "lib/vuex/plugins/persist"
import Vue from "vue"
import Vuex, {MutationTree, Store} from "vuex"
import accrual from "./accrual/index"
import communication from "./communication/index"
import participant from "./participant/index"
import rules from "./rules/index"
import customerJourneys from "./customerJourneys/index"
import {RootState, Locale} from "./types"
import load from "./load"

import pensiongoal from "./pensiongoal/index"
import pensiondetails from "./pensiondetails/index"
import investments from "./investments/index"
import riskprofile from "./riskprofile/index"
import variablepension from "./variablepension/index"

Vue.use(Vuex)

const rootState: RootState = {
	timestamp: 0,
	lastLogin: null,
	active: false,
	logout: false,
	grossFactor: 0.91765
}

const mutations: MutationTree<RootState> = {
	timestamp(state) {
		state.timestamp = Date.now()
	},
	logout(state) {
		state.logout = true
		state.active = false
	},
	login(state) {
		state.active = true
		state.logout = false
	},
	locale(state, locale: Locale) {
		state.locale = locale
	}
}

const plugins = process.env.SERVER ? [] : [
	persist({
		...storageOptions,
		blacklist: ["text"],
		deactivate: s => s.state.expired
	}),
	async () => {
		await Vue.nextTick()
		eventBus.emit("loaded")
	}
]

const store = new Store<RootState>({
	modules: {
		participant,
		accrual,
		communication,
		rules,
		customerJourneys,
		pensiondetails,
		pensiongoal,
		riskprofile,
		variablepension,
		investments
	},
	getters: {
		pensionAge: state => {
			const pensionDate = state.accrual && state.accrual.client.length && state.accrual.client[0].oap.length
				? state.accrual.client[0].oap[0].startDate : undefined
			if (pensionDate && state.participant) {
				return diff(Diff.Years, state.participant.birthDate, pensionDate)
			}
			return undefined
		},
		grossFactor: state => state.grossFactor
	},
	actions: {
		async login(context): Promise<boolean> {
			await load(this)
			context.commit("login")
			context.commit("timestamp")
			return true
		}
	},
	mutations,
	plugins,
	state: cloneDeep(rootState),
	strict: process.env.NODE_ENV !== "production"
})

export default store
