import {Module} from "vuex"
import diff, {Diff} from "lib/date/diff"
import {RootState} from "../types"
import {Communication, Document} from "./types"
import {participantURL, documentDownloadURL} from "../settings"
import {getters, mutations, actions} from "timeline/store/communication/index"
import {post} from "../request"

const communicationState: Communication = {
	pensionCommunication: true,
	financialUpdates: false,
	newsletter: false,
	messages: [],
	documents: []
}

const save = post(participantURL)
const markMessage = post(`${participantURL}markmessageread/`)

const communication: Module<Communication, RootState> = {
	actions: {
		...actions(save, markMessage),
		downloadDocument: (context, payload: Document) => {
			context.commit("documentDownloaded", payload)
			// TODO: this is error prone. If the document doesn't exist, then we leave the application and get a 404 or worse.
			// Should be solved using a link with target blank.
			window.open(documentDownloadURL(payload.id), "_blank")
		}
	},
	getters: {
		...getters,
		hasUPOUitDienst: state => state.documents.some(document => document.type === "UPOUitDienst" && diff(Diff.Months, document.date, new Date()) < 6),
		startedEmploymentLast6Months: (_state, _getters, rootState) =>
			rootState.participant!.newEmployeeDate ? diff(Diff.Months, rootState.participant!.newEmployeeDate!, new Date()) < 6 : false
	},
	mutations,
	namespaced: true,
	state: communicationState
}

export default communication
