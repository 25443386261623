import {RootState} from "../types"
import {Riskprofile} from "./types"
import {Module} from "vuex"

const riskprofileState: Riskprofile = {
	isEdited: false,
	profile: undefined,
	riskCharts: {
		defensive: { title: "A", min: 0, mid: 0, max: 0 },
		neutral: { title: "B", min: 0, mid: 0, max: 0 },
		offensive: { title: "C", min: 0, mid: 0, max: 0 }
	}
}

const riskprofile: Module<Riskprofile, RootState> = {
	actions: {},
	getters: {
		riskCharts: state => state.riskCharts,
		profile: state => state.profile || "",
		profileName: state => !state.profile ? "" : state.profile === "defensive" ? "defensief" : state.profile === "neutral" ? "neutraal" : "offensief"
	},
	mutations: {
		hydrate(state, data: Riskprofile) {
			if (!state.isEdited) {
				Object.assign(state, data)
			}
		},
		setProfile(state, profile: "defensive" | "neutral" | "offensive") {
			state.isEdited = true
			state.profile = profile
		}
	},
	namespaced: true,
	state: riskprofileState
}

export default riskprofile
