import {Riskprofile, MetaphorData, RiskCharts} from "./types"
import {ConversionMap} from "lib/types/import"
import {one, mandatory} from "lib/import/extract"
import {numeric, alphanumeric} from "lib/import/convert"

const required = true
const optional = false

const profile = (value: any) => ["defensive", "neutral", "offensive"].includes(value) ? value : undefined

const metaphor: ConversionMap<MetaphorData> = {
	title: ["title", alphanumeric, required],
	min: ["min", numeric, required],
	mid: ["mid", numeric, required],
	max: ["max", numeric, required]
}

const riskChart: ConversionMap<RiskCharts> = {
	defensive: ["defensive", one(metaphor), required],
	neutral: ["neutral", one(metaphor), required],
	offensive: ["offensive", one(metaphor), required]
}

const riskprofile: ConversionMap<Riskprofile> = {
	isEdited: ["nonexistent", () => false, required],
	profile: ["attributes.riskprofile.profile", profile, optional],
	riskCharts: ["attributes.riskprofile.riskCharts", one(riskChart), required]
}

export const convert = mandatory(one(riskprofile))
