import {Participant} from "timeline/store/participant/types"
import {MaritalStatus} from "lib/types/personal"

export {Partner, Person, Address} from "timeline/store/participant/types"

export interface Employment {
	employers: ReadonlyArray<string>,
	salary: number
	parttimePercentage: number
}

export interface MeubelParticipant extends Participant {
	maritalStatus: MaritalStatus,
	aowDate: Date,
	newEmployeeDate?: Date
	hasOpKoersBasisAccess: Access
	hasOpKoersPlannerAccess: Access
	totalFinalAmount: number
	employment: Employment
}

export interface Access {
	result: boolean
	reason: Reason
}

export type Reason =
	"too-young" |
	"too-old" |
	"REASONPENSIONER" |
	"REASONPARTIALLYPENSIONED" |
	"REASONPARTIALAO" |
	"REASONMULTIPLEACTIVEPLANS" |
	"REASONDISPENSATION" |
	"REASONREGRET" |
	"none"
