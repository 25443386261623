import RequestError from "lib/request/RequestError"

export default (input: RequestInfo, method: string) => async (data?: any): Promise<Response> => {
	const response = await fetch(input, {
		method,
		headers: {
			"Content-Type": "application/json"
		},
		mode: "cors",
		credentials: "include",
		body: method === "GET" || method === "HEAD" ? undefined : JSON.stringify({ data })
	})
	if (response.status === 401) {
		return Promise.reject(new RequestError(response))
	}
	return response
}
