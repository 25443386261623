import {Variablepension, MetaphorData, VariablePensions, VariableMetaphors} from "./types"
import {ConversionMap} from "lib/types/import"
import {one, mandatory} from "lib/import/extract"
import {numeric, alphanumeric} from "lib/import/convert"

const required = true
const optional = false

const metaphor: ConversionMap<MetaphorData> = {
	title: ["title", alphanumeric, required],
	min: ["min", numeric, required],
	mid: ["mid", numeric, required],
	max: ["max", numeric, required]
}

const vpMetaphor: ConversionMap<MetaphorData & {details: string}> = {
	...metaphor,
	details: ["details", alphanumeric, required]
}

const profiles: ConversionMap<{fixed: MetaphorData, variable: MetaphorData}> = {
	fixed: ["fixed", one(metaphor), required],
	variable: ["variable", one(metaphor), required]
}

const variableMetaphors = <returnType>(...keys: Array<keyof VariableMetaphors>) => (value: any) => {
	return keys.reduce((obj, key) => ({...obj, [key]: one(vpMetaphor)(value[key])}), {}) as returnType
}

const variablepensions: ConversionMap<VariablePensions> = {
	defensive: ["defensive", variableMetaphors("act_0", "act_25"), required],
	neutral: ["neutral", variableMetaphors("act_0", "act_25", "act_50"), required],
	offensive: ["offensive", variableMetaphors("act_0", "act_25", "act_50", "act_75", "act_100"), required]
}

const variablepension: ConversionMap<Variablepension> = {
	isEdited: ["nonexistent", () => false, required],
	profiles: ["attributes.variablepension.profiles", one(profiles), required],
	variablePensions: ["attributes.variablepension.variablePensions", one(variablepensions), required],
	currentProfile: ["attributes.variablepension.currentProfile", one(metaphor), optional]
}

export const convert = mandatory(one(variablepension))
