import {GetterTree, Module} from "vuex"
import {RootState} from "../types"

const getters: GetterTree<undefined, RootState> = {
	customerJourneyEnabled: (_state, _getters, rootState) => rootState.participant!.hasOpKoersPlannerAccess,
	hasPartnerPension: (_state, _getters, rootState) => rootState.accrual!.client.some(
		pensionPlan => pensionPlan.pp.some(pp => pp.finalAmount > 0)
	)
}

const rules: Module<undefined, RootState> = {
	getters,
	namespaced: true
}

export default rules
