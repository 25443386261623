import {Locale} from "lib/types/i18n"

export default (locale: Locale) => (n: number, decimals: number = 0): string => {
	const [whole, fraction] = n.toFixed(decimals).split(".")
	let formatted = whole
	const regex = /(\d+)(\d{3})/
	const replace = `$1${locale.separator.thousands}$2`
	while (regex.test(formatted)) {
		formatted = formatted.replace(regex, replace)
	}
	if (fraction) {
		formatted += `${locale.separator.decimals}${fraction}`
	}

	return formatted
}
