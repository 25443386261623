

















































































import Vue from "vue"
import Component from "vue-class-component"
import {Mutation, namespace, State} from "vuex-class"

import {IconDocument, IconEnvelope, IconPerson, IconPencil, IconRepeat, IconFolder, IconPiggybank} from "custom/icons"

import ActionButton from "components/button/ActionButton.vue"
import Badge from "components/supplemental/Badge.vue"
import Hamburger from "custom/Hamburger.vue"
import IconLabel from "custom/IconLabel.vue"
import NavigationBar from "components/container/NavigationBar.vue"

import RequestModal from "custom/modal/RequestModal.vue"
import DossierModal from "custom/modal/DossierModal.vue"
import MenuModal from "custom/modal/MenuModal.vue"
import MessageModal from "custom/modal/MessageModal.vue"
import ProfileModal from "custom/modal/ProfileModal.vue"
import TraceModal from "custom/modal/TraceModal.vue"

import {MeubelParticipant} from "store/participant/types"
import {Locale} from "store/types"

import load from "src/i18n/load"
import {save as saveLocale} from "lib/i18n/session"

const communication = namespace("communication")

@Component({
	components: {
		ActionButton,
		Badge,
		Hamburger,
		IconLabel,
		NavigationBar,
		RequestModal,
		DossierModal,
		MenuModal,
		MessageModal,
		ProfileModal,
		TraceModal,

		// icons
		IconDocument,
		IconEnvelope,
		IconPerson,
		IconPencil,
		IconRepeat,
		IconFolder,
		IconPiggybank
	}
})
export default class PageHeader extends Vue {
	@State active!: boolean
	@Mutation logout!: () => void

	@State participant!: MeubelParticipant
	@State locale!: Locale

	@communication.Getter unreadMessages!: number
	@communication.Getter unreadDocuments!: number

	menuModalOpened = false

	async changeLanguage(lang: Locale) {
		const done = await saveLocale(load, lang)
		if (done) {
			this.$store.commit("locale", lang)
			location.reload()
		}
	}

	constructUrl(url: string) {
		return !process.env.SERVER
		? `${url}?person=${this.participant.firstName}`.toLowerCase()
		: url
	}

	goToURL(url: string) {
		window.location.href = this.constructUrl(url)
	}
}
