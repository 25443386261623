import {Module} from "vuex"
import {RootState} from "../types"
import {CustomerJourneys} from "./types"
import {mutations, actions} from "timeline/store/customerJourneys/index"

import {post} from "../request"
import {customerJourneyURL} from "../settings"

const customerJourneys: Module<CustomerJourneys, RootState> = {
	state: {},
	mutations,
	actions: actions(post(customerJourneyURL), post(customerJourneyURL + "step/")),
	namespaced: true
}

export default customerJourneys
