import {Gender, MaritalStatus, Status} from "lib/types/personal"
import {Locale} from "lib/types/i18n"

export const gender = (locale: Locale) =>  (value: Gender) => {
	switch (value) {
		case Gender.Male: return locale.gender.male
		case Gender.Female: return locale.gender.female
		case Gender.Unknown: return locale.gender.unknown
	}
	return ""
}

export const maritalStatus = (locale: Locale) => (value: MaritalStatus) => {
	switch (value) {
		case MaritalStatus.Divorced: return locale.maritalStatus.divorced
		case MaritalStatus.LegalCohabitation: return locale.maritalStatus.legalCohabitation
		case MaritalStatus.LivingTogether: return locale.maritalStatus.livingTogether
		case MaritalStatus.Married: return locale.maritalStatus.married
		case MaritalStatus.Single: return locale.maritalStatus.single
		case MaritalStatus.Unknown: return locale.maritalStatus.unknown
	}
	return ""
}

export const status = (locale: Locale) => (value: Status) => {
	switch (value) {
		case Status.Active: return locale.status.active
		case Status.Disabled: return locale.status.disabled
		case Status.Inactive: return locale.status.inactive
		case Status.Pensioner: return locale.status.pensioner
		case Status.Unknown: return locale.status.unknown
	}
	return ""
}
