import {ActionTree} from "vuex"
import {Submit, ResponseHandler} from "lib/types/request"
import {FieldRules, FieldErrors} from "lib/types/validation"
import validations from "lib/validation/validations"
import FieldValidationError from "lib/validation/FieldValidationError"
import entries from "lib/misc/entries"

export default <S, R>(fieldRules: FieldRules<S>, submit: Submit, errors: ResponseHandler<FieldErrors<any>>): ActionTree<S, R> => {
	const tree: ActionTree<S, R> = {}

	for (const [field, validate] of entries(validations(fieldRules))) {
		tree[field as string] = async ({ commit }, payload) => {
			const clientErrors = validate(payload)
			if (clientErrors.length) {
				throw new FieldValidationError(clientErrors)
			}

			const response = await submit({[field]: payload})

			if (!response.ok) {
				const serverErrors = await errors(response)
				throw new FieldValidationError(serverErrors)
			}

			commit(field as string, payload)
		}
	}

	return tree
}
