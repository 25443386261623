import {ConversionMap} from "lib/types/import"
import {one, mandatory} from "lib/import/extract"
import {datetime, bool, numeric} from "lib/import/convert"
import {maritalStatus} from "timeline/import/convert"
import {participant as participantMap} from "timeline/store/participant/conversion"
import {MeubelParticipant, Access} from "./types"

const required = true
const optional = false
const reasons = [
	"too-young",
	"too-old",
	"REASONPENSIONER",
	"REASONPARTIALLYPENSIONED",
	"REASONPARTIALAO",
	"REASONMULTIPLEACTIVEPLANS",
	"REASONDISPENSATION",
	"REASONREGRET",
	"REASONNOTACTIVE",
	"none"
]

const opkoersReason = (value: any) => {
	if (reasons.includes(value)) {
		return value
	} else {
		return undefined
	}
}

const access: ConversionMap<Access> = {
	result: ["result", bool, required],
	reason: ["reason", opkoersReason, required]
}

const participant: ConversionMap<MeubelParticipant> = {
	...participantMap,
	maritalStatus: ["attributes.maritalstatus", maritalStatus, required],
	aowDate: ["attributes.aowDate", datetime, required],
	aowAge: ["nonexistent", () => 0, required], // Override default: we receive aow date. Calculate on hydrate.
	newEmployeeDate: ["attributes.extendedattributes.newemployeedatetime", datetime, optional],
	hasOpKoersBasisAccess: ["attributes.extendedattributes.hasOpKoersBasisAcces", one(access), required],
	hasOpKoersPlannerAccess: ["attributes.extendedattributes.hasOpKoersPlannerAcces", one(access), required],
	totalFinalAmount: ["attributes.extendedattributes.totalFinalAmount", numeric, required],
	employment: ["nonexistent", () => ({salary: 0, parttimePercentage: 0, employers: []}), required]
}

export const convert = mandatory(one(participant))
