import request from "./request"
import {ErrorHandler, Submit} from "lib/types/request"

/**
 * Returns a function that performs a GET request to the server.
 *
 * @returns {Submit} The function that performs the GET.
 */
export default (input: RequestInfo, failure: ErrorHandler<any>): Submit => {
	const submit = request(input, "GET")
	return () => submit().catch(failure)
}
