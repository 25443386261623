import {Options} from "lib/types/i18n"

export default (locale: string): Promise<{default: Options}> => {
	switch (locale) {
		case "nl_NL": return import("i18n/nl_NL")
		case "en_UK": return import("i18n/en_UK")
	}

	return Promise.reject(new ReferenceError(`Unknown locale ${locale}`))
}
