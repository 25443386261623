












import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import {namespace, } from "vuex-class"
import {StoreAction} from "lib/types/vuex"

import MessageList from "custom/MessageList.vue"

import {Message} from "store/communication/types"

const communication = namespace("communication")

@Component({
	components: {
		MessageList
	}
})
export default class MessageBox extends Vue {
	@Prop({type: Array, required: true}) messages!: Array<Message>
	@Prop({type: Object, required: false}) selected!: Message

	@communication.Action messageRead!: StoreAction<Message>

	selectedMessage: Message | null = null

	@Watch("selected")
	selectedChanged() {
		this.selectMessage(this.selected)
	}

	selectMessage(message: Message) {
		this.selectedMessage = message
		/* tslint:disable:no-floating-promises */
		this.messageRead(message)
	}
}
