









import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class Metaphor extends Vue {
	@Prop({required: true, type: String}) left!: string
	@Prop({required: true, type: String}) top!: string
	@Prop({required: true, type: String}) right!: string
}
