import {FieldRules} from "lib/types/validation"
import {email, iban} from "lib/validation/formats"
import {Participant} from "./types"

const participantRules: FieldRules<Participant> = {
	email: {
		type: "string",
		required: true,
		valid: email
	},
	iban: {
		type: "string",
		required: false,
		valid: iban
	}
}

export default participantRules
