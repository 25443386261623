




import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class ValidationMessage extends Vue {
	@Prop({required: true, type: String}) code!: string
	@Prop({required: true, type: Object}) error!: any

	get codes() {
		return this.code.split(",")
	}
}
