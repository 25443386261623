import Vue from "vue"
import {ActionTree, MutationTree} from "vuex"
import {CustomerJourneys, StatusPayload, StepPayload} from "./types"
import {Submit} from "lib/types/request"

export const mutations: MutationTree<CustomerJourneys> = {
	hydrate(state, data: CustomerJourneys) {
		for (const key of Object.keys(data)) {
			Vue.set(state, key, data[key])
		}
	},
	status(state, data: StatusPayload) {
		const customerJourney = state[data.code]
		if (!customerJourney) {
			Vue.set(state, data.code, {
				status: data.status,
				version: data.version
			})
		} else {
			customerJourney.status = data.status
		}
	},
	step(state, data: StepPayload) {
		const customerJourney = state[data.code]
		if (!customerJourney) {
			throw new ReferenceError(`Customer Journey ${data.code} does not exist`)
		}
		if (!customerJourney.steps) {
			Vue.set(customerJourney, "steps", {})
		}
		Vue.set(customerJourney.steps!, data.step, {...data, date: new Date()})
	}
}

export const actions = <R>(submitStatus: Submit, submitStep: Submit): ActionTree<CustomerJourneys, R> => ({
	status: async ({commit}, payload: StatusPayload) => {
		commit("status", payload)
		await submitStatus({
			icsCustomerJourney: {
				type: "icsCustomerJourney",
				attributes: {
					variant: "",
					...payload
				}
			}
		})
	},
	step: async ({commit}, payload: StepPayload) => {
		commit("step", payload)
		await submitStep({
			icsCustomerJourneyStep: {
				type: "icsCustomerJourneyStep",
				attributes: {
					variant: "",
					...payload
				}
			}
		})
	}
})
