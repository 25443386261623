










import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

import {IconCheckmark} from "custom/icons"

export enum TrackerStatus {
	Pending,
	Processing,
	Complete,
	Error
}

@Component({
	components: {
		IconCheckmark
	}
})
export default class TrackerItem extends Vue {
	@Prop({required: true, type: Number}) status!: TrackerStatus
}
