import {ConversionMap} from "lib/types/import"
import {alphanumeric, datetime, numeric} from "lib/import/convert"
import {one, many, mandatory} from "lib/import/extract"
import {Accrual, PartnerPension, PensionPlan, OldAgePension} from "./types"

const required = true
const optional = false

const oldAgePension: ConversionMap<OldAgePension> = {
	title: ["attributes.typeDescription", alphanumeric, required],
	currentAmount: ["attributes.currentAmount", numeric, required],
	finalAmount: ["attributes.finalAmount", numeric, required],
	startDate: ["attributes.pensionStartDate", datetime, required],
	endDate: ["attributes.pensionEndDate", datetime, optional],
	type: ["nonexistent", () => "DB", required] // Field does not exist in data?
}
const partnerPension: ConversionMap<PartnerPension> = {
	title: ["attributes.typeDescription", alphanumeric, required],
	currentAmount: ["attributes.currentAmount", numeric, required],
	finalAmount: ["attributes.finalAmount", numeric, required]
}
const pensionPlan: ConversionMap<PensionPlan> = {
	active: ["insurancestatus",
		value => value === "active" ? true : value === "inactive" || value === "pensioner" || value === "unknown" ? false : undefined, required],
	startDate: ["insuranceStartDate", datetime, required],
	endDate: ["insuranceEndDate", datetime, optional],
	insurancenumber: ["insurancenumber", alphanumeric, required],
	employer: ["employer", alphanumeric, required],
	salary: ["insuranceSalary", numeric, required],
	parttimePercentage: ["insuranceParttimePercentage", numeric, required],
	oap: ["pensionPositions", many(oldAgePension, pension => pension.attributes.type === "1"), required],
	pp: ["pensionPositions", many(partnerPension, pension => pension.attributes.type === "10"), required],
	title: ["regulationtypedescription", alphanumeric, required]
}
const accrual: ConversionMap<Accrual> = {
	client: ["attributes.pensionplans", many(pensionPlan), required],
	other: ["nonexistent", () => [], required]
}

export const convert = mandatory(one(accrual))
