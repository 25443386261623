import {Module} from "vuex"
import {RootState} from "../types"
import {post} from "../request"
import {participantURL} from "../settings"
import {MeubelParticipant, Employment} from "./types"
import diff, {Diff} from "lib/date/diff"
import {Gender, Status, MaritalStatus} from "lib/types/personal"
import {actions, getters, mutations} from "timeline/store/participant"

const participantState: MeubelParticipant = {
	aowDate: new Date(0),
	aowAge: 0,
	addresses: [],
	birthDate: new Date(0),
	citizenServiceNumber: "",
	email: undefined,
	gender: Gender.Unknown,
	iban: undefined,
	initials: undefined,
	firstName: undefined,
	lastName: "",
	maritalStatus: MaritalStatus.Unknown,
	phoneNumbers: [],
	prefix: undefined,
	partners: [],
	relationNumber: "",
	status: Status.Unknown,
	newEmployeeDate: undefined,
	hasOpKoersBasisAccess: {
		reason: "too-young",
		result: false
	},
	hasOpKoersPlannerAccess: {
		reason: "too-young",
		result: false
	},
	employment: {
		salary: 0,
		parttimePercentage: 0,
		employers: []
	},
	totalFinalAmount: 0
}

const participant: Module<MeubelParticipant, RootState> = {
	actions: {
		...actions(post(participantURL)),
		employment: context => {
			const pensionPlans = context.rootState.accrual!.client.filter(pensionPlan => pensionPlan.active)
			context.commit("employment", {
				salary: pensionPlans.reduce((total, pensionPlan) => total + pensionPlan.salary * pensionPlan.parttimePercentage / 100, 0),
				parttimePercentage: pensionPlans.reduce((total, pensionPlan) => total + pensionPlan.parttimePercentage, 0),
				employers: pensionPlans.map(pensionPlan => pensionPlan.employer)
			})
		}
	},
	getters,
	mutations: {
		...mutations,
		hydrate(state, data: MeubelParticipant) {
			data.aowAge = diff(Diff.Months, data.birthDate, data.aowDate) / 12
			Object.assign(state, data)
		},
		employment: (state, employment: Employment) => {
			state.employment = employment
		},
		mutateEmail: (state, value: string) => {
			state.email = value
		}
	},
	namespaced: true,
	state: participantState
}

export default participant
