import {ActionTree, GetterTree, MutationTree} from "vuex"
import {Communication, Message, Document} from "./types"
import diff, {Diff} from "lib/date/diff"
import {Submit} from "lib/types/request"

const dateDiffMillis = <T extends { date: Date }>(a: T, b: T) => diff(Diff.Millis, a.date, b.date)
export const getters: GetterTree<Communication, any> = ({
	communication: state => state,
	unreadMessages: state => state.messages.reduce((count, message) => count + (!message.read ? 1 : 0), 0),
	unreadDocuments: state => state.documents.reduce((count, document) => count + (!document.downloaded ? 1 : 0), 0),
	messages: state => [...state.messages].sort(dateDiffMillis),
	documents: state => [...state.documents].sort(dateDiffMillis)
})

export const mutations: MutationTree<Communication> = {
	hydrate(state, data: Communication) {
		Object.assign(state, data)
	},
	newsletter(state, value: boolean) {
		state.newsletter = value
	},
	financialUpdates(state, value: boolean) {
		state.financialUpdates = value
	},
	pensionCommunication(state, value: boolean) {
		state.pensionCommunication = value
	},
	messageRead(state, message: Message) {
		if (state.messages.includes(message)) {
			message.read = true
		}
	},
	documentDownloaded(state, document: Document) {
		if (state.documents.includes(document)) {
			document.downloaded = true
		}
	}
}

export const actions = (submit: Submit, markMessage: Submit): ActionTree<Communication, any> => ({
	async newsletter(context, payload: boolean) {
		await submit({newsletterpension: payload})
		context.commit("newsletter", payload)
	},
	async financialUpdates(context, payload: boolean) {
		await submit({digitalfinancialupdates: payload})
		context.commit("financialUpdates", payload)
	},
	async pensionCommunication(context, payload: boolean) {
		await submit({digitalpensioncommunication: payload})
		context.commit("pensionCommunication", payload)
	},
	async messageRead(context, payload: Message) {
		if (!payload.read) {
			await markMessage({messageID: payload.id})
			context.commit("messageRead", payload)
		}
	},
	downloadDocument(context, payload: Document) {
		context.commit("documentDownloaded", payload)
	}
})
