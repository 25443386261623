



















import Vue from "vue"
import Component from "vue-class-component"
import {namespace} from "vuex-class"

import AccordionItem from "custom/AccordionItem.vue"
import AccordionList from "custom/AccordionList.vue"
import DocumentLink from "custom/DocumentLink.vue"
import {IconEnvelope, IconEnvelopeOpened} from "custom/icons"

import LinkButton from "components/button/LinkButton.vue"
import Modal from "components/modal/Modal.vue"

import {Document} from "store/communication/types"

import {StoreAction} from "lib/types/vuex"

const communication = namespace("communication")

@Component({
	components: {
		AccordionItem,
		AccordionList,
		DocumentLink,
		LinkButton,
		IconEnvelope,
		IconEnvelopeOpened,
		Modal
	}
})
export default class DossierModal extends Vue {
	@communication.Getter documents!: Array<Document>
	@communication.Action downloadDocument!: StoreAction<Document>
}
