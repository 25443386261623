import {RootState} from "../types"
import {Variablepension, MetaphorData} from "./types"
import {Module, GetterTree} from "vuex"
import {isUndefined} from "lodash-es"

const getters: GetterTree<Variablepension, any> = {
	variablePensions: state => state.variablePensions,
	profiles: state => state.profiles,
	hasProfile: state => !isUndefined(state.currentProfile)
}

const variablepensionState: Variablepension = {
	isEdited: false,
	profiles: {
		fixed: { title: "Vast", min: 0, mid: 0, max: 0 },
		variable: { title: "Variabel", min: 0, mid: 0, max: 0 }
	},
	variablePensions: {
		defensive: {
			act_0: { title: "Actieve Lifecycle Defensief", min: 2856, mid: 5644, max: 12528, details: "" },
			act_25: { title: "Actieve Lifecycle Defensief 25% doorbeleggen", min: 2887, mid: 5644, max: 13330, details: "" }
		},
		neutral: {
			act_0: { title: "Actieve Lifecycle Neutraal", min: 2891, mid: 5964, max: 14480, details: "" },
			act_25: { title: "Actieve Lifecycle Neutraal 25% doorbeleggen", min: 2851, mid: 5954, max: 15177, details: "" },
			act_50: { title: "Actieve Lifecycle Neutraal 50% doorbeleggen", min: 2825, mid: 6034, max: 15861, details: "" }
		},
		offensive: {
			act_0: { title: "Actieve Lifecycle Offensief", min: 2869, mid: 6067, max: 15283, details: "" },
			act_25: { title: "Actieve Lifecycle Offensief 25% doorbeleggen", min: 2830, mid: 6067, max: 15756, details: "" },
			act_50: { title: "Actieve Lifecycle Offensief 50% doorbeleggen", min: 2813, mid: 6114, max: 16377, details: "" },
			act_75: { title: "Actieve Lifecycle Offensief 75% doorbeleggen", min: 2788, mid: 6172, max: 17130, details: "" },
			act_100: { title: "Actieve Lifecycle Offensief 100% doorbeleggen", min: 2762, mid: 6239, max: 17846, details: "" }
		}
	},
	currentProfile: undefined
}

const variablepension: Module<Variablepension, RootState> = {
	actions: {},
	getters,
	mutations: {
		hydrate(state, data: Variablepension) {
			if (!state.isEdited) {
				Object.assign(state, data)
			}
		},
		setCurrentProfile(state, data: MetaphorData) {
			state.currentProfile = data
		}
	},
	namespaced: true,
	state: variablepensionState
}

export default variablepension
