import {RootState} from "../types"
import {Investments} from "./types"
import {Module, GetterTree} from "vuex"

const getters: GetterTree<Investments, any> = {
	total: state => state.graph.datasets.reduce((acc, ds) => acc + ds.data[ds.data.length - 1], 0)
}

const investmentsState: Investments = {
	transactionGroups: [],
	graph: {
		title: "",
		labels: [],
		datasets: []
	}
}

const investments: Module<Investments, RootState> = {
	actions: {},
	getters,
	mutations: {
		hydrate(state, data: Investments) {
			Object.assign(state, data)
		}
	},
	namespaced: true,
	state: investmentsState
}

export default investments
