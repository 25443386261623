import {ConversionFunction} from "lib/types/import"
import {Gender, MaritalStatus, PhoneType, Status} from "lib/types/personal"

export const gender: ConversionFunction<Gender> = value => {
	switch (value) {
		case "F": return Gender.Female
		case "M": return Gender.Male
		case "X": return Gender.Third
		case "U": return Gender.Unknown
		default: return undefined
	}
}

export const maritalStatus: ConversionFunction<MaritalStatus> = value => {
	switch (value) {
		case "divorced": return MaritalStatus.Divorced
		case "legalcohabitation": return MaritalStatus.LegalCohabitation
		case "livingtogether": return MaritalStatus.LivingTogether
		case "married": return MaritalStatus.Married
		case "single": return MaritalStatus.Single
		case "unknown": return MaritalStatus.Unknown
		default: return undefined
	}
}

export const phoneType: ConversionFunction<PhoneType> = value => {
	switch (value) {
		case "phoneMobile": return PhoneType.Mobile
		case "phonePrivate": return PhoneType.Landline
		case "phone": return PhoneType.Other
		case "unknown": return PhoneType.Unknown
		default: return undefined
	}
}

export const status: ConversionFunction<Status> = value => {
	switch (value) {
		case "1": return Status.Active
		case "2": return Status.Inactive
		case "3": return Status.Pensioner
		default: return undefined
	}
}
