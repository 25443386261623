










import Component from "vue-class-component"
import InputField from "./InputField"

let id = 0

@Component
export default class FileField extends InputField<File | null> {
	message = "geen bestand geselecteerd"
	id = ""

	created() {
		this.id = `file-field-${id}`
		id += 1
	}

	fileSelected() {
		const files = (this.$el.querySelector("#" + this.id) as HTMLInputElement).files
		if (files) {
			this.contents = files[0]
			this.message = files[0].name
		}
	}
}
