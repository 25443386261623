import RequestError from "lib/request/RequestError"
import timelineGet from "timeline/request/get"
import timelinePost from "timeline/request/post"
import store from "./index"

const failure = (e: Error) => {
	if (e instanceof RequestError) {
		store.commit("logout")
		location.href = "/login/"
	}
	return Promise.reject(e)
}

export const get = (input: RequestInfo) => timelineGet(input, failure)

export const post = (input: RequestInfo) => timelinePost(input, failure)
