import {ConversionMap} from "lib/types/import"
import {one, many} from "lib/import/extract"
import {alphanumeric, date, numeric} from "lib/import/convert"
import {gender, maritalStatus, phoneType, status} from "timeline/import/convert"

import {Address, Participant, Partner, Person, PhoneNumber} from "./types"

const required = true
const optional = false

export const address: ConversionMap<Address> = {
	city: ["attributes.city", alphanumeric, optional],
	country: ["attributes.countryName", alphanumeric, required],
	extra: ["attributes.extra", alphanumeric, optional],
	number: ["attributes.nr", alphanumeric, optional],
	add: ["attributes.add", alphanumeric, optional],
	street: ["attributes.street", alphanumeric, required],
	zipcode: ["attributes.zipcode", alphanumeric, optional],
	state: ["attributes.state", alphanumeric, optional],
	lastChanged: ["attributes.lastChangedDate", date, optional]
}
export const phoneNumber: ConversionMap<PhoneNumber> = {
	type: ["attributes.type", phoneType, required],
	number: ["attributes.nr", alphanumeric, required]
}
export const person: ConversionMap<Person> = {
	birthDate: ["attributes.birthdate", date, required],
	citizenServiceNumber: ["attributes.citizenservicenumber", alphanumeric, required],
	firstName: ["attributes.firstname", alphanumeric, optional],
	gender: ["attributes.gender", gender, required],
	iban: ["attributes.IBAN", alphanumeric, optional],
	initials: ["attributes.initials", alphanumeric, optional],
	lastName: ["attributes.lastname", alphanumeric, required],
	prefix: ["attributes.prefix", alphanumeric, optional],
	relationNumber: ["attributes.relationnumber", alphanumeric, required]
}
export const partner: ConversionMap<Partner> = {
	maritalStatus: ["attributes.maritalstatus", maritalStatus, required],
	maritalDate: ["attributes.maritaldate", date, optional],
	divorceDate: ["attributes.divorcedate", date, optional],
	...person
}
export const participant: ConversionMap<Participant> = {
	aowAge: ["attributes.aowAge", numeric, required],
	email: ["attributes.email", alphanumeric, optional],
	addresses: ["attributes.addresses", many(address), required],
	phoneNumbers: ["attributes.phonenumbers", many(phoneNumber), required],
	partners: ["attributes.partners", many(partner), required],
	status: ["attributes.status", status, required],
	...person
}

export const convert = one(participant)
