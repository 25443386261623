import {FieldErrors} from "lib/types/validation"

export default class FieldValidationError<F extends keyof T, T = any> extends Error {

	readonly errors: FieldErrors<F>

	constructor(errors: FieldErrors<F>) {
		super("Validation Error")
		this.errors = errors
	}

}
