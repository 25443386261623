import {ConversionMap} from "lib/types/import"
import {one, many} from "lib/import/extract"
import {alphanumeric, datetime, bool} from "lib/import/convert"
import {Communication, Document, Message} from "./types"

const required = true
const optional = false

const message: ConversionMap<Message> = {
	date: ["date", datetime, required],
	id: ["messageid", alphanumeric, required],
	read: ["read", bool, required],
	title: ["title", alphanumeric, required],
	body: ["body", alphanumeric, required],
	validFrom: ["validFromDateTime", datetime, optional],
	validUntil: ["validTillDateTime", datetime, optional]
}
const document: ConversionMap<Document> = {
	date: ["date", datetime, required],
	id: ["id", alphanumeric, required],
	downloaded: ["downloaded", bool, required],
	filename: ["filename", alphanumeric, required],
	type: ["type", alphanumeric, optional],
	title: ["title", alphanumeric, required]
}
const communication: ConversionMap<Communication> = {
	pensionCommunication: ["attributes.digitalpensioncommunication", bool, required],
	financialUpdates: ["attributes.digitalfinancialupdates", bool, required],
	newsletter: ["attributes.newsletterpension", bool, required],
	messages: ["attributes.messages", many(message), required],
	documents: ["attributes.documents", many(document), required]
}

export const convert = one(communication)
