import identity from "lib/function/identity"
import {last, obj, one} from "lib/import/extract"
import {numeric} from "lib/import/convert"
import {ConversionMap} from "lib/types/import"
import {CustomerJourney, Step} from "./types"
import {get} from "lodash-es"

const required = true
const optional = false

const step: ConversionMap<Step> = {
	data: ["attributes.data", identity, optional],
	date: ["attributes.datetime", () => new Date(), required]
}

export const customerJourney: ConversionMap<CustomerJourney> = {
	status: ["status", value => value === "pending" || value === "started" || value === "completed" ? value : undefined, required],
	version: ["version", numeric, required],
	steps: ["steps", obj(one(step)), optional]
}

// The customer journeys are stored in an object {code: array of customer journey data}.
// We are only interested in the last data for every customer journey.
const customerJourneys = obj(last(customerJourney))
export const convert = (value: any) => customerJourneys(get(value, "attributes.ICSCustomerJourneys"))!
