




import Vue from "vue"
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"

@Component
export default class DropdownOption<T> extends Vue {
	@Prop({ default: "" }) value!: T
}
