const rootURL = "/"
const rootURLDemo = "/"

// DEMO //
let myParam = null
let participantURLTemp = `${rootURL}api/participant/`
let documentDownloadURLTemp = (id: string) => `${rootURL}?timelineApiMethod=downloadDocument&documentID=${id}`
const isBrowser = !process.env.SERVER

if (isBrowser) {
	const urlParams = new URLSearchParams(window.location.search)
	if (urlParams.get("person")) {
	myParam = urlParams.get("person")
	if (myParam) {
		myParam = myParam.toLowerCase()
	}
	participantURLTemp = `${rootURLDemo}static/${myParam}.json`
	documentDownloadURLTemp = (id: string) => `${rootURLDemo}static/${id}`
	}
}
// DEMO //

export const participantURL = participantURLTemp
export const ontrackURL = `${participantURL}ontrack/`
export const customerJourneyURL = `${participantURL}icscustomerjourney/`
export const sessionTimeout = 15 * 60 * 1000

export const documentDownloadURL = documentDownloadURLTemp
